//Start Module: Menu
{
    let menu = window.vodafone.menu || new Object({});
    window.vodafone.data = window.vodafone.data || new Object({});

    const subMenuLinkClass = '.has-submenu';
    const subMenuClass = '.menu--submenu';
    const hamburgerBtnClass = '.hamburger button';
    const modalBackdropClass = document.getElementsByClassName("menu-backdrop");

    let subMenuLink =   document.querySelectorAll(subMenuLinkClass);
    let subMenuDiv =   document.querySelectorAll(subMenuClass);
    let hamburgerBtn = document.querySelector(hamburgerBtnClass);
    let i;

    menu.init = () => {
        Array.from(subMenuLink).forEach((trigger) => {
            trigger.addEventListener('click', (event) => {
                if(trigger.nextElementSibling.classList.contains('open')){
                    trigger.nextElementSibling.classList.remove('open');
                } else {
                    for(i=0; i < subMenuDiv.length; i++){
                        subMenuDiv[i].classList.remove('open');
                    }
                    trigger.nextElementSibling.classList.add('open');
                }
            });
        });
        
        if(hamburgerBtn){
            hamburgerBtn.addEventListener('click', (event) => {
                hamburgerBtn.parentNode.classList.toggle('active');
                document.querySelector('.header--content--right').classList.toggle('active');
                document.body.classList.toggle('menu-open');
    
                let modalBackdropDiv = document.createElement('div');
                modalBackdropDiv.className = 'menu-backdrop';
                if(hamburgerBtn.parentNode.classList.contains('active')){
                    document.body.appendChild(modalBackdropDiv);
                } else {
                    while (modalBackdropClass.length > 0) modalBackdropClass[0].remove();
                }
            });
        }

        window.onresize = function(){
            if(hamburgerBtn){
                hamburgerBtn.parentNode.classList.remove('active');
                document.querySelector('.header--content--right').classList.remove('active');
                document.body.classList.remove('menu-open');
                while (modalBackdropClass.length > 0) modalBackdropClass[0].remove();
            }
        }

        window.vodafone.data = window.vodafone.data || new Object({});
    };

    window.vodafone.menu = menu;
};
//End Module: Menu