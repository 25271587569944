//Start Module: Datepicker
{
    let datepicker = window.vodafone.datepicker || new Object({});
    window.vodafone.data = window.vodafone.data || new Object({});

    datepicker.init = () => {
        $('[data-toggle="datepicker"]').datepicker();

        window.vodafone.data = window.vodafone.data || new Object({});
    };

    window.vodafone.datepicker = datepicker;
};
//End Module: Datepicker