const appInit = () => {
    window.vodafone.menu.init();
    window.vodafone.dataTable.init();
    window.vodafone.datepicker.init();
    window.vodafone.progressBar.init();
    window.vodafone.formValidation.init();
}

if (document.readyState === 'loading'){
    document.addEventListener('DOMContentLoaded', () =>{
        appInit();
    });
} else {
    appInit();
}