//Start Module: chosenSelect
{
    let chosenSelect = window.vodafone.chosenSelect || new Object({});
    // window.vodafone.data = window.vodafone.data || new Object({});

    chosenSelect.init = () => {
        // let x =document.getElementsByClassName('data-table');
        // document.write(x);
        chosenSelect.createChosen($('.chosen-select'));

        // window.vodafone.data = window.vodafone.data || new Object({});
    };

    chosenSelect.createChosen = (selects) => {
        if(!selects.jquery) selects = $(selects);
        selects
            .chosen({disable_search: true, width: '70px'})
    }

    window.vodafone.chosenSelect = chosenSelect;
};
//End Module: chosenSelect