//Start Module: Progress Bar
{
    let progressBar = window.vodafone.progressBar || new Object({});
    window.vodafone.data = window.vodafone.data || new Object({});

    progressBar.init = () => {
        let progressBarContainer = document.querySelector('.progress .progress-bar');
        progress(60, 60, progressBarContainer);        
    }

    function progress(timeLeft, timeTotal, element){
        if(element){
            element.style.transitionDuration = timeTotal + 6 + 's';
            element.style.width= '0%';
            let countDownTimer = element.parentNode.parentNode.querySelector('.countDown');
            countDownTimer.innerHTML = formatTimeString(timeLeft);
            
            timeLeft -= 1;
    
            if(timeLeft >= 0){
                setTimeout(function(){
                    progress(timeLeft, timeTotal, element)
                },1000);
            }
        }
    }

    function formatTimeString(time){
        let minutes = parseInt(time / 60, 10)
        let seconds = parseInt(time % 60, 10);
  
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
  
        return minutes + ':' + seconds;        
    }

    window.vodafone.progressBar = progressBar;
}
//End Module: Progress Bar