{
    let dataTable = window.vodafone.dataTable || new Object({});
    // window.vodafone.data = window.vodafone.data || new Object({});
    let inputModalTriggerSelector = 'input[data-toggle="modal"]';
    let tableChkAllClass = '.headerRow .toggle-all';
    let dataTableTblClass = '.data-table-container table';

    const inputModalTrigger = document.querySelectorAll(inputModalTriggerSelector);
    const tableChkAll = document.querySelectorAll(tableChkAllClass);
    const dataTableTbl = document.querySelectorAll(dataTableTblClass);
    
    let i;
    let tblOrdering;

    Array.from(dataTableTbl).forEach((trigger) => {
        if(trigger.getAttribute('data-order') == 'false'){
            tblOrdering = false;
        } else {
            tblOrdering = true;
        }
    });

    dataTable.init = () => {
        jQuery('table.non-selectable-table.display').DataTable({
            scrollX: true,
            "pagingType": "simple",
            "dom": '<"top"f>rt<"bottom"ilp><"clear">',
            colReorder: true,
            language: {
                lengthMenu: "Mostra: _MENU_",
                zeroRecords: "Nothing found - sorry",
                info: "_START_-_END_ di _TOTAL_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                search: '', searchPlaceholder: "Cerca per cognome o username",
                paginate: {
                    next: '<img src="../../ump/assets/images/icon-table-next.svg">',
                    previous: '<img src="../../ump/assets/images/icon-table-previous.svg">'  
                }
            },
            drawCallback: function(obj) {
                var tableId = this.api().tables().nodes().to$().attr('id');
                if(this.api().page.info().pages < 2){
                    document.getElementById(tableId).closest('.data-table-container').getElementsByClassName('bottom')[0].style.display = "none";
                }
            }
        });
        let table = jQuery('table.selectable-table.display').DataTable({
            scrollX: true,
            "pagingType": "simple",
            "dom": '<"top"f>rt<"bottom"ilp><"clear">',
            select: true,
            colReorder: true,
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                lengthMenu: "Mostra: _MENU_",
                zeroRecords: "Nothing found - sorry",
                info: "_START_-_END_ di _TOTAL_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                search: '', searchPlaceholder: "Cerca per cognome o username",
                paginate: {
                    next: '<img src="../../ump/assets/images/icon-table-next.svg">',
                    previous: '<img src="../../ump/assets/images/icon-table-previous.svg">'  
                }
            },
            columnDefs: [ {
                orderable: false,
                className: 'select-checkbox',
                targets:   0
            } ],
            select: {
                style:    'multi',
                selector: 'td:first-child',
                info: false
            },
            order: [[ 1, 'asc' ]],
            ordering: tblOrdering,
            drawCallback: function(obj) {
                var tableId = this.api().tables().nodes().to$().attr('id');
                if(this.api().page.info().pages < 2){
                    document.getElementById(tableId).closest('.data-table-container').getElementsByClassName('bottom')[0].style.display = "none";
                }
            }
        });

        table.on( 'select.dt deselect.dt',  function (evtObj) {
            let curTable = table.table(evtObj.currentTarget);
            let all = curTable.rows().count();
            let sel = curTable.rows(".selected").count();
          
            if(all === sel){
                evtObj.currentTarget.closest('.data-table-container').querySelector(".chk-vodafone.toggle-all").checked = true;
            }
            else{
                evtObj.currentTarget.closest('.data-table-container').querySelector(".chk-vodafone.toggle-all").checked = false;
            }
        });

        //Search placeholder
        const tableInputPlaceholder = document.querySelectorAll('.dataTables_filter input');            
        Array.from(tableInputPlaceholder).forEach((item) => {
            if(item.closest('.data-table-container').querySelector('table').hasAttribute('data-search')){
                item.placeholder = item.closest('.data-table-container').querySelector('table').getAttribute('data-search');
            }
        });

        // select unselect all rows
        Array.from(tableChkAll).forEach((trigger) => {
            trigger.addEventListener('click', (event) => {
                let curTable = table.table(trigger.closest('.data-table-container').getElementsByTagName('table'));
                if(trigger.checked){
                    curTable.rows().select();
                } else {
                    curTable.rows().deselect();
                }
            });
        });
          
        //Initalise the chosen select for the datatables
        const dataTableLength = document.querySelectorAll('div.dataTables_length select');
        Array.from(dataTableLength).forEach((item) => {
            item.classList.add('chosen-select');
        });
        window.vodafone.chosenSelect.init();

        //on modal open make tables full width
        Array.from(inputModalTrigger).forEach((trigger) => {
            let modalId = document.querySelector(trigger.getAttribute('data-target'));
            $(modalId).on('shown.bs.modal', function () {
                table.columns.adjust();
            });
        });
        
        window.vodafone.data = window.vodafone.data || new Object({});
    };

    window.vodafone.dataTable = dataTable;
};