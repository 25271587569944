//Start Module: Form Validation
{
    let formValidation = window.vodafone.formValidation || new Object({});

    const defaultMessages = {
        required: "Questo è un campo obbligatorio",
        invalidEntry: "Inserimento non valido.",
        patternMismatch: 'Il modello non corrisponde',
        typeMismatch: "Tipo di dati errato",
        tooShort: "Troppo corto",
        tooLong: "Troppo lungo",
        default: "default msg"
    };

    const FORMS_CLASS = '.frm-vodafone-login';
    const HAS_ERROR = 'has-error';
    const VODAFONE_MSG = 'vodafone-msg';
    const VODAFONE_MSG_ERR = 'vodafone-error-msg';

    let forms = document.querySelectorAll(FORMS_CLASS);
    let newPwd = document.querySelectorAll('input.frm-vodafone-login--new-password');
    let confirmPwd = document.querySelectorAll('input.frm-vodafone-login--confirm-password');
    let otpNum = document.querySelectorAll('.frm-vodafone-login--otp');
    let loginUserName = document.querySelectorAll('input.frm-vodafone-login--user-name');
    let emailForPwd = document.querySelectorAll('input.frm-vodafone-login--email-for-pwd')

    formValidation.init = () => {

        formValidation.formValidate();

    };

    formValidation.formValidate= () => {
        Array.from(forms).forEach((form) => {
            let formElements = form.querySelectorAll('input, select, textarea');

            Array.from(formElements).forEach((element) => {

                if(element.hasAttribute('required')){
                    element.addEventListener('keyup', (event) => {
                        if (element.checkValidity()) {
                            console.log('validity is true');
                            element.parentNode.classList.remove(HAS_ERROR);
                            formValidation.removeErrorMessage(element);
                        } else {
                            console.log('false of checkvalidity');
                            element.parentNode.classList.add(HAS_ERROR);
                            formValidation.addErrorMessage(element);
                        }
                    });
                }
            });
        });
    };

    formValidation.addErrorMessage = (element) => {
        if (element.type !== 'radio' && element.type !== 'checkbox' && element.type !== 'select-one' && element.type !== 'select-multiple') {
            let messageElement = element.parentNode.querySelector('.' + VODAFONE_MSG);
    
            if (messageElement === null) {
                let newMessageElement = document.createElement('span');
                newMessageElement.classList.add(VODAFONE_MSG);
                newMessageElement.classList.add(VODAFONE_MSG_ERR);
                element.parentNode.appendChild(newMessageElement);
                messageElement = element.parentNode.querySelector('.' + VODAFONE_MSG);
            }
    
            messageElement.innerHTML = "";
            if (element.validity.valueMissing) {
                if(element.getAttribute('data-error-msg-required') != 'undefined'){
                    messageElement.innerHTML = element.getAttribute('data-error-msg-required');
                }else{
                    messageElement.innerHTML = defaultMessages.required;
                }
            } else if(element.validity.patternMismatch) {
                if(element.getAttribute('data-error-msg-pattern-mismatch') != 'undefined'){
                    messageElement.innerHTML = element.getAttribute('data-error-msg-pattern-mismatch')
                }else {
                    messageElement.innerHTML = defaultMessages.patternMismatch;
                }   
            } else if(element.validity.tooShort) {
                if(element.getAttribute('data-error-msg-too-short') != 'undefined'){
                    messageElement.innerHTML = element.getAttribute('data-error-msg-too-short');
                }else{
                    messageElement.innerHTML = defaultMessages.tooShort;
                }
            } else if(element.validity.tooLong) {
                if(element.getAttribute('data-error-msg-too-long') != 'undefined'){
                    messageElement.innerHTML = element.getAttribute('data-error-msg-too-long');
                }else{
                    messageElement.innerHTML = defaultMessages.tooLong;
                }
            } else if(element.validity.invalidEntry){
                if(element.getAttribute('data-error-msg-invalid-entry') != 'undefined'){
                    messageElement.innerHTML = element.getAttribute('data-error-msg-invalid-entry');
                }else{
                    messageElement.innerHTML = defaultMessages.invalidEntry;
                }

            } else {
                if(element.getAttribute('data-error-msg-default') != undefined){
                    console.log('default msg expected');
                    messageElement.innerHTML = element.getAttribute('data-error-msg-default');
                }else{
                    messageElement.innerHTML = defaultMessages.default;
                }
            }
        }
    };

    formValidation.removeErrorMessage = (element) => {
        while (element.parentNode.querySelector('.' + VODAFONE_MSG)) {
            element.parentNode.querySelector('.' + VODAFONE_MSG).remove();
        }
    };

    //Validate the new password
    if(newPwd){
        Array.from(newPwd).forEach((pwd) => {
            pwd.addEventListener('keyup', function(e){
                let pwdPattern = /^(?=.*\d)(?=.*[A-Z]).{6,}$/;
                pwd.parentElement.classList.add(HAS_ERROR);
                if(pwd.value.match(pwdPattern)){
                    e.stopImmediatePropagation();
                    e.currentTarget.parentNode.classList.remove(HAS_ERROR);
                    formValidation.removeErrorMessage(e.currentTarget);
                }else{
                    e.stopImmediatePropagation();
                    e.currentTarget.parentNode.classList.remove(HAS_ERROR);
                    e.currentTarget.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(e.currentTarget);
                }
            });
        });
    }

    //Validate to see if confirm password matches with new password
    if(confirmPwd){
        Array.from(confirmPwd).forEach((confPwd) => {
            confPwd.addEventListener('keyup', function(e){
                if(confPwd.value === confPwd.parentNode.parentNode.querySelector('input.frm-vodafone-login--new-password').value){
                    e.stopImmediatePropagation();
                    confPwd.parentNode.classList.remove(HAS_ERROR);
                    formValidation.removeErrorMessage(confPwd);
                    if(document.querySelector(".frm-vodafone-login--old-password").value.length > 0){
                        confPwd.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').removeAttribute("disabled");
                    }
                }else{
                    e.stopImmediatePropagation();
                    confPwd.parentNode.classList.remove(HAS_ERROR);
                    confPwd.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(confPwd);
                    confPwd.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').setAttribute("disabled", "disabled");
                }
            });
        });
    }

    //Validate for OTP
    if(otpNum){
        Array.from(otpNum).forEach((otp) => {
            otp.addEventListener('keyup', function(e){
                if(otp.value.length < 5){
                    e.stopImmediatePropagation();
                    otp.parentNode.classList.remove(HAS_ERROR);
                    otp.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(this);
                    otp.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').setAttribute("disabled", "disabled");
                }else if(otp.value.length == 5){
                    e.stopImmediatePropagation();
                    otp.parentNode.classList.remove(HAS_ERROR);
                    formValidation.removeErrorMessage(otp);
                    otp.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').removeAttribute("disabled");
                }
            });

            otp.addEventListener('keydown', function(event){
                var charCode = (event.which) ? event.which : event.keyCode
                if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)){
                    event.preventDefault();
                }
            });
        });
    }

    //Validate for User name to have no space
    if(loginUserName){
        Array.from(loginUserName).forEach((loginUser) => {
            loginUser.addEventListener('keyup', function(event){
                if(loginUser.value.indexOf(' ')>= 0){
                    event.stopImmediatePropagation();
                    loginUser.parentNode.classList.remove(HAS_ERROR);
                    loginUser.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(loginUser);
                    loginUser.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').setAttribute("disabled", "disabled");
                }else if(loginUser.value === ""){
                    event.stopImmediatePropagation();
                    loginUser.parentNode.classList.remove(HAS_ERROR);
                    loginUser.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(loginUser);
                    loginUser.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').setAttribute("disabled", "disabled");
                }else{
                    event.stopImmediatePropagation();
                    loginUser.parentNode.classList.remove(HAS_ERROR);
                    formValidation.removeErrorMessage(loginUser);
                    loginUser.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').removeAttribute('disabled');
                }
            });
        });
    }

    //Validate for Email Id
    if(emailForPwd){
        Array.from(emailForPwd).forEach((email) => {         
            email.addEventListener('keyup', function(event){
                let emailPatter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
                if(email.value.match(emailPatter)){
                    event.stopImmediatePropagation();
                    email.parentNode.classList.remove(HAS_ERROR);
                    formValidation.removeErrorMessage(email);
                    email.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').removeAttribute("disabled");
                }else{
                    event.stopImmediatePropagation();
                    email.parentNode.classList.remove(HAS_ERROR);
                    email.parentNode.classList.add(HAS_ERROR);
                    formValidation.addErrorMessage(email);
                    email.parentNode.parentNode.parentNode.querySelector('.frm-vodafone--footer').querySelector('.btn--wrap').querySelector('.login-btn').setAttribute("disabled", "disabled");
                }
            });
        });
    }

    window.vodafone.formValidation = formValidation;
}

//End Module : Form Validation